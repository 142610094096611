.button {
    cursor: pointer;
    vertical-align: middle !important;
    padding: 4px 12px;
    margin-left: 10px;
    border: 1px solid;
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    text-transform: uppercase;

    &:active {
        opacity: 0.8; 
    }
    
    &:hover {
        color: var(--ion-primary-color);
        border: 1px solid;
    }

    &-secondary {
        //background-color: var(--ion-tertiary-color);
    }
}
