.header {
    display: flex;
    padding-top: 15px;
    padding-bottom: 20px;
    justify-content: space-between;
    .icon {
        display: flex;
        justify-content: center;
        width: 140px;
        img {
            margin-bottom: -35px;
            padding: 0 20px;
        }
    }

    &-left, &-right {
        display: flex;
        align-items: flex-end;
        flex: 0.5;
        border-bottom: 1px solid white;
        padding-bottom: 10px
    }

    &-left {
        justify-content: flex-end;
        .header-button {
            margin-left: 15px;
        }
    }

    &-right {
        .header-button {
            margin-right: 15px;
        }
    }

    &-button {
        cursor: pointer;
        &:hover {
            color: var(--ion-primary-color);
        }
    }
    .active{
        color: var(--ion-primary-color);
    }
}

.top-message {
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    background: var(--ion-primary-color);
    padding: 10px;
    text-align: center;
    justify-content: center;
    color: var(--ion-tertiary-color);
}