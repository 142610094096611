.fixture {
    position: relative;
    margin: 15px 0;
    background-color: var(--ion-secondary-shadow);
    overflow: hidden;

    &-header {
        min-height: 180px;
        &::after {
            pointer-events: none;
            position: absolute;
            content: "";
            background: url('http://exprostudio.com/html/soccer/images/bg-pattran.png');
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-size: 150%;
        }
        overflow: hidden;
        display: flex;
        cursor: pointer;
        &-left {
            position: relative;
            display: flex;
            flex: 1;
            background: transparent;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-bottom: 1px solid var(--ion-primary-color);

            &-title {
                h1 {
                    font-size: 25px;
                    font-weight: bold;
                    @media (max-width:500px) {
                        font-size: 18px;
                    }
                    span {
                        margin-right: 8px;
                        font-weight: 800;
                        color: var(--ion-primary-color);
                }
                }

                h3 {
                    font-size: 20px;
                    @media (max-width:500px) {
                        font-size: 16px;
                    }
                }
                small {
                    font-size: 12px;
                }
            }
        }

        &-right {
            
            position: relative;
            display: flex;
            flex-direction: column;
            width: 150px;
            background: var(--ion-primary-color);
            justify-content: center;
            align-items: center;
            padding: 10px 0;

            @media (max-width:500px) {
                width: 100px;
            }
            
            
            img {
                padding: 10px;
                max-width: 90px;
                @media (max-width:500px) {
                    max-width: 80px;
                }
            }
            b {
                color: var(--ion-tertiary-color);
                display: flex;
                white-space: nowrap;
                padding: 0 10px 10px 10px;
                margin-left: -5%;
                @media (max-width:500px) {
                    font-size: 14px;
                }
            }
            &::before {
                top: 0;
                width: 0;
                height: 0;
                right: 100%;
                content: "";
                position: absolute;
                border-bottom: 700px solid var(--ion-primary-color);
                border-left: 0px solid transparent;
            }
        }
    }

    &-body {
        padding: 20px;
        &-item {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 30px;
            margin-bottom: 30px;
            background: rgba(255, 255, 255, 0.05);
            padding: 10px;
    
            .date {
                position: absolute;
                text-align: center;
                right: 0px;
                top: -20px;
                font-size: 12px;
            }
    
            .teams {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                height: 60px;

                h1 {
                    font-size: 50px;
                    font-weight: bold;
                    @media (max-width:500px) {
                        font-size: 35px;
                    }
                }
    
                &-left, &-right {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 50%;
                    text-transform: uppercase;
                }
                &-right {
                    justify-content: flex-end;
                    .teams-title {
                        text-align: right;
                    }
                }

                &-logo {
                    height: 30px;
                    width: 30px;
                }
                &-title {
                    display: flex;
                    font-size: 14px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }

            .selector {
                cursor: pointer;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -10px;
                margin: auto;
                margin-top: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 22px;
                background-color: var(--ion-tertiary-color);
                width: 190px;
            
                span {
                    font-size: 12px;
                    width: 65px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 22px;
                    text-align: center;
                }
                .selected {
                    border: 1px solid var(--ion-primary-color);
                }
                .dashed {
                    font-weight: bold;
                    color: var(--ion-tertiary-color);
                    background-color: var(--ion-primary-color);
                }
                .check, .error {
                    &::before {
                        position: absolute;
                        top: 1px;
                        right: -26px;
                        content: '✓';
                        color: white;
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background: green;
                    }
                }

                .error::before {
                    content: '✕';
                    background: red;
                }
            }
            
        }
    }
}

