.wrapper{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.background{
   position: absolute;
   background-color: rgba(0, 0, 0, 0.5);
   opacity: 1;
   height: 100%;
   width: 100%
}

.hidden {
    display: none;
}

