@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;600&display=swap');

@import  "~bootstrap/scss/bootstrap" ;
@import "./button.scss";

* {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
 
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 800px;
    }
}




.link{
    text-decoration: none;
    color: var(--ion-text-primary);
    &:hover{
        color: var(--ion-text-primary);
        opacity: 0.8;
    }

    
}



