.modal {
    margin-top: 80px;
    &-options {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        &-option {
            margin: 0px 20px;
            padding: 4px 12px;
            width: 80px;
            text-align: center;
            background-color: var(--ion-secondary-shadow);
            font-weight: bolder;
            color: var(--ion-text-color);
            border: solid 1px var(--ion-text-color);
            &:hover {
                opacity: 0.8;
                color: var(--ion-primary-color);
                border: solid 1px var(--ion-primary-color);
            }
        }
        .active{
            color: var(--ion-primary-color);
        border: solid 1px var(--ion-primary-color);
        }
    }
    &-backdrop {
        background-color: var(--ion-secondary-shadow);
    }
    &-content {
        color: var(--ion-text-color);
        background-color: var(--ion-secondary-color) !important;
    }
    &-header {
        color: var(--ion-text-color);
        border: none;
    }
    &-close {
        display: none;
    }
    &-footer {
        border: none !important;
    }
}
