.card-bet {
    
    position: relative;
    margin-bottom: 10px;
    background-color: var(--ion-secondary-shadow);
    border-radius: 2px;
    padding: 15px;
    overflow: hidden;
    padding-top: 40px;
    &::after {
        pointer-events: none;
        position: absolute;
        content: "";
        background: url('http://exprostudio.com/html/soccer/images/bg-pattran.png');
        opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-size: 150%;
    }

    .currency {
        display: flex;
        position: absolute;
        font-size: 14px;
        top: 10px;
        right: 10px;
        padding: 0px 5px;
        border: 1px solid;
    }

    .text{
        font-size: 14px;
    }
    .country {
        font-weight: bold;
        color: var(--ion-primary-color);
        margin-right: 5px;
    }

    .status {
        display: flex;
        position: absolute;
        font-size: 12px;
        top: 10px;
        left: 10px;
        padding: 0px 5px;
  
        &.pending {
            background-color: var(--ion-secondary-shadow);
        }
        &.confirmed {
            background-color: var(--ion-secondary-shadow);
        }
    }
}
