.carousel-item {
    background-color: var(--ion-secondary-shadow);

    h1 {
        font-size: 60px;
        font-weight: bold;
        color: var(--ion-primary-color);
        @media (max-width: 768px) {
            font-size: 50px;
        }
    }
    h3 {
        margin: auto;

        width: 80%;
        font-size: 16px;
        font-weight: bold;
    }
}
.carousel-control-next {
   
    text-decoration: none;
    margin-right: -10px;
   
    opacity: 1;
    span {
        transform: rotate(-90deg);
       
        color: var(--ion-primary-color);
        font-weight: bold;
        width: 55px;
        border-bottom: solid 2px var(--ion-primary-color);
    }
    &:hover {
        opacity: 0.8;
        color: var(--ion-primary-color);
    }
}
.carousel-control-prev {
    text-decoration: none;
    margin-left: -10px;
  
    opacity: 1;
    span {
        transform: rotate(90deg);
        color: var(--ion-primary-color);
        font-weight: bold;
        width: 55px;
        border-bottom: solid 2px var(--ion-primary-color);
    }
    &:hover {
        opacity: 0.8;
        color: var(--ion-primary-color);
    }
}
.carousel-image {
    opacity: 1;
    position: relative;
    align-self: center;
    z-index: -1;
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.carousel {
    margin: 30px 0;
    position: relative;
    
}


