.hisotry{
    
    margin-top: 20px;
    .input {
        width: 100%;
        border: solid 1px var(--ion-text-color) !important;
        border-radius: 2px;
        padding: 5px 5px;
   
        font-size: 14px;
        background-color: transparent;
        border: none;
        &:focus-visible {
            outline: none;
        }
       
    }
    .button-primary {
        cursor: pointer;
        width: 100px;
        vertical-align: middle !important;
        padding: 4px 12px;
        background-color: var(--ion-primary-color);
        
        &:active {
            opacity: 0.8; 
        }
    }
    .details{
        font-size: 12px;
        span{
            text-decoration: underline;
        }
    }
    .open-bet{
        
        cursor: pointer;
        font-weight: 600;
    }
}