.bet {
    margin: 30px 0px;
    .deposit_address {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        .address_text {
            
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .copied {
        margin-left: 8px;

        color: var(--ion-primary-color);
    }
    .downloaded {
        margin: 15px 0px;
        color: var(--ion-primary-color);
    }
    .copy-icon {
        margin-left: 5px;
        font-size: 22px;
        color: var(--ion-primary-color);
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }
    .matches {
        padding: 10px 20px;
        .match {
            display: flex;
            justify-content: center;

            .team {
                border-radius: 2px;
                font-size: 14px;
                width: 125px;
                text-align: center;
                padding: 2px;
                margin: 5px;
                background-color: var(--ion-secondary-shadow);
            }
            .winner {
                background-color: var(--ion-primary-color);
            }
            .tie {
                background-color: var(--ion-primary-shadow);
            }
            .separator {
                padding-top: 5px;
                text-align: center;
                width: 20px;
            }
        }
    }
}
