
:root {
    --ion-background-color: #373542;
    --ion-text-color:  #ffffff;
    --ion-primary-color: #c3e200;
    --ion-secondary-color: #262629;
    --ion-tertiary-color: #242424;
    --ion-primary-shadow: #8a4fff67;
    --ion-secondary-shadow: #36363631;
}
