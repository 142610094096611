.footer {
   
    display: flex;
    position:unset;
    bottom: 0px;
    justify-content: center;
    background-color: var(--ion-secondary-color);
    width: 100%;
    font-size: 12px;
    padding: 8px;
    margin-top: 40px;

}
